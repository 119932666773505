<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="productobg" src="../assets/nosotros_bg.png" alt="">
    <div class="title">NUESTRA EMPRESA</div>
    <div class="box">
      <div class="item">
        <div class="tit big">KHASMENT</div>
        <div class="txt">Somos una plataforma que proporciona préstamos en línea rápidos, accesibles y seguros. Regístrate en segundos, solicita un préstamo y recibe tu dinero en minutos.</div>
      </div>
      <div class="item">
        <div class="tit">Vemos el mundo de otra manera.</div>
        <div class="txt">Nos impulsa una creencia fundamental en las personas y trabajamos juntos para demostrar su potencial. Tomamos riesgos que otros no se atreven a tomar, poniendo más poder en manos de nuestros clientes.</div>
      </div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  padding-top: 100px;

  .productobg {
    width: 100%;
    background-color: #000;
    vertical-align: middle;
  }
  >.title {
    height: 100px;
    line-height: 100px;
    background: linear-gradient( 45deg, #D5EE71 0%, #7BE2C8 100%);
    text-align: center;
    font-family: GTWalsheim, GTWalsheim;
    font-weight: bold;
    font-size: 40px;
    color: #0E0E0E;
  }
  .box {
    padding: 40px 80px;
    background-color: #0E0E0E;

    .item {
      margin-bottom: 30px;

      .tit {
        margin-bottom: 10px;
        display: inline-block;
        font-family: GillSans;
        font-size: 24px;
        line-height: 40px;
        background-image: linear-gradient(90deg, #D5EE71 0%, #7BE2C8 100%);
        -webkit-background-clip: text;
        color: transparent;
        &.big {
          font-size: 40px;
          font-weight: bold;
        }
      }
      .txt {
        font-family: GillSans;
        font-size: 18px;
        color: #E7FFF9;
        line-height: 24px;
      }
    }
  }
}
</style>
