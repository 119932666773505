<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="productobg" src="../assets/ayuda_bg.png" alt="">
    <div class="title">PREGUNTA FRECUENTE</div>
    <div class="box">
      <div class="item">
        <div class="tit">¿Cuáles son los requisitos para solicitar un préstamo en la plataforma?</div>
        <div class="txt">Elegibilidad para solicitar un préstamo:</div>
        <div class="txt">Ser residente legal y mayor de 18 años.</div>
        <div class="txt">Tener una cédula de identidad vigente.</div>
        <div class="txt">Contar con un trabajo estable y ingresos mensuales fijos.</div>
        <div class="txt">Poseer una cuenta bancaria activa a tu nombre, según la tarjeta de identificación.</div>
      </div>
      <div class="item">
        <div class="tit">¿Por qué fue rechazada mi solicitud?</div>
        <div class="txt">Tu solicitud puede haber sido rechazada por varias razones basadas en la evaluación de tu perfil crediticio. No te preocupes, puedes volver a presentar la solicitud en la fecha indicada en la aplicación.</div>
      </div>
      <div class="item">
        <div class="tit">¿Cuáles son los pasos necesarios para solicitar un préstamo?</div>
        <div class="txt">El proceso de solicitud de préstamo es muy sencillo y solo requiere cuatro pasos:</div>
        <div class="txt">Descarga nuestra APLICACIÓN desde Google Play Store.</div>
        <div class="txt">Regístrate con tu número de teléfono móvil.</div>
        <div class="txt">Carga tu tarjeta de identidad y realiza el reconocimiento facial.</div>
        <div class="txt">Envía la solicitud de préstamo.</div>
      </div>
      <div class="item">
        <div class="tit">¿Cuánto tiempo lleva el proceso de solicitud?</div>
        <div class="txt">Analizaremos tu solicitud en solo 10 minutos. Asegúrate de responder cuando nos comuniquemos contigo para la verificación.</div>
      </div>
      <div class="item">
        <div class="tit">¿Cómo veo el estado de mi solicitud?</div>
        <div class="txt">Puedes verificar el estado de tu solicitud en la página del préstamo dentro de la aplicación.</div>
      </div>
      <div class="item">
        <div class="tit">¿Cuánto desembolso del préstamo recibiré?</div>
        <div class="txt">Puedes ver el monto exacto del préstamo que recibirás directamente en la aplicación al momento de realizar la solicitud.</div>
      </div>
      <div class="item">
        <div class="tit">edo solicitar un nuevo préstamo antes de completar mi préstamo anterior?</div>
        <div class="txt">Sí, puedes solicitar un nuevo préstamo siempre que el límite de tu préstamo todavía esté disponible.</div>
      </div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  padding-top: 100px;

  .productobg {
    width: 100%;
    background-color: #000;
    vertical-align: middle;
  }

  .title {
    height: 100px;
    line-height: 100px;
    background: linear-gradient( 45deg, #D5EE71 0%, #7BE2C8 100%);
    text-align: center;
    font-family: GTWalsheim, GTWalsheim;
    font-weight: bold;
    font-size: 40px;
    color: #0E0E0E;
  }
  .box {
    padding: 40px 80px;
    background-color: #0E0E0E;
    .item {
      margin-bottom: 30px;
      .tit {
        margin-bottom: 10px;
        display: inline-block;
        font-family: GillSans;
        font-size: 24px;
        line-height: 40px;
        background-image: linear-gradient(90deg, #D5EE71 0%, #7BE2C8 100%);
        -webkit-background-clip: text;
        color: transparent;
      }
      .txt {
        font-family: GillSans;
        font-size: 18px;
        color: #E7FFF9;
        line-height: 24px;
      }
    }
  }
}
</style>
