<template>
  <div class="footer-com">
    <div class="top">
      <div class="title">KHASMENT</div>
      <div class="info">KHASMENT S.A.C. es una empresa registrada en Perú. :</div>
      <div class="flex">
        <div class="item">
          <div class="tit">Correo :</div>
          <div class="txt">contacto@khasmentsac.com</div>
        </div>
        <div class="item">
          <div class="tit">Teléfono :</div>
          <div class="txt">+51 319877621</div>
        </div>
        <div class="item">
          <div class="tit">WhatsApp :</div>
          <div class="txt">+51 319877621</div>
        </div>
      </div>
    </div>
    <div class="bottom">El préstamo será aprobado si se cumplen los requisitos y condiciones crediticias evaluadas previamente a través de nuestra plataforma web.</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.footer-com {
  .top {
    padding: 30px;
    background: linear-gradient( 45deg, #D5EE71 0%, #7BE2C8 100%);
    .title {
      font-family: Lovelo, Lovelo;
      font-weight: 900;
      font-size: 30px;
      color: #0E0E0E;
      line-height: 45px;
      text-align: center;
    }
    .info {
      font-family: GillSans;
      font-size: 18px;
      color: #0E0E0E;
      line-height: 24px;
      text-align: center;
    }
    .flex {
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-evenly;
      .item {
        .tit {
          font-family: GillSans;
          font-size: 20px;
          color: #0E0E0E;
        }
        .txt {
          font-family: GillSans;
          font-size: 18px;
          color: #0E0E0E;
          margin-top: 15px;
        }
      }
    }
  }
  .bottom {
    padding: 20px 100px;
    background: #0E0E0E;
    font-family: GillSans;
    font-size: 18px;
    color: #E7FFF9;
    line-height: 24px;
    text-align: left;
  }
}
</style>