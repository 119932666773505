<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="productobg" src="../assets/product_bg.png" alt="">
    <div class="box">
      <div class="title">Nuestro Servicio</div>
      <div class="info">Ofrecemos una amplia gama de préstamos personales a corto plazo, con plazos que van desde 91 días hasta 365 días. Nos enorgullece proporcionar soluciones financieras de primer nivel a usuarios de todo el mundo.</div>
      <div class="info">Con nuestra publicidad personalizada en Google y Meta (antes Facebook), utilizamos análisis de datos avanzados, políticas de control de riesgos rigurosas y soluciones de pago integrales para garantizar que los préstamos personales sean rápidos y accesibles para todos los ciudadanos legales. Haz que tus necesidades financieras sean cosa del pasado con nuestros préstamos instantáneos y disfruta de la tranquilidad que mereces.</div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  padding-top: 100px;

  .productobg {
    width: 100%;
    background-color: #000;
    vertical-align: middle;
  }
  .box {
    padding: 40px 80px;
    background-color: #0E0E0E;

    .title {
      margin-bottom: 10px;
      display: inline-block;
      font-family: GillSans;
      font-size: 30px;
      line-height: 40px;
      background-image: linear-gradient(90deg, #D5EE71 0%, #7BE2C8 100%);
      -webkit-background-clip: text;
      color: transparent;
    }
    .info {
      font-family: GillSans;
      font-size: 18px;
      color: #E7FFF9;
      line-height: 30px;
    }
  }
}
</style>
