<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="homebg" src="../assets/home_bg.png" alt="">
    <div class="title">NUESTRA COMPETENCIA</div>
    <div class="content">
      <img class="home_img1" src="../assets/home_img1.png" alt="">
      <div class="home_img5">
        <img class="tit" src="../assets/home_img5.png" alt="">
        <img class="item" src="../assets/home_img2.png" alt="">
        <img class="item" src="../assets/home_img3.png" alt="">
        <img class="item" src="../assets/home_img4.png" alt="">
      </div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
  .home-page {
    width: 100%;
    padding-top: 100px;

    .homebg {
      width: 100%;
      vertical-align: middle;
      background: #000;
      user-select: none;
    }
    .title {
      height: 100px;
      line-height: 100px;
      background: linear-gradient( 45deg, #D5EE71 0%, #7BE2C8 100%);
      text-align: center;
      font-family: GTWalsheim, GTWalsheim;
      font-weight: bold;
      font-size: 40px;
      color: #0E0E0E;
    }
    .content {
      padding: 70px;
      background: #0E0E0E;

      .home_img1 {
        width: 100%;
      }
      .home_img5 {
        margin: 30px 0px;
        padding: 0px 70px;
        text-align: center;
        .tit {
          width: 60%;
        }
        .item {
          width: 100%;
          margin: 20px 0px;
        }
      }
    }
  }
</style>
