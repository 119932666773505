<template>
  <div class="header-com">
    <div class="wrap">
      <div class="left-area">
        <img class="img" src="../../assets/logo.png" width="400px;height:80px;" />
      </div>
      <div class="right-area">
        <ul class="list">
          <router-link tag="li" class="item" to="/inicio">INCIO</router-link>
          <router-link tag="li" class="item" to="/producto">PRODUCTO</router-link>
          <router-link tag="li" class="item" to="/ayuda">AYUDA</router-link>
          <router-link tag="li" class="item" to="/nosotros">NOSOTROS</router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import minxinLocalStorage from "../../common/minxinLocalStorage.js";
export default {
  mixins: [minxinLocalStorage],
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.header-com {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;

  .wrap {
    display: flex;
    justify-content: space-between;
    height: 100px;
    line-height: 100px;
    background: #000000;

    .left-area {
      padding-left: 76px;
      .img {
        width: 150px;
        vertical-align: middle;
      }
    }

    .right-area {
      text-align: right;

      .list {
        padding-right: 76px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          display: inline-block;
          height: 34px;
          line-height: 34px;
          padding: 0 15px;
          font-family: Humanist777BT, Humanist777BT;
          color: #fff;
          font-size: 16px;
          cursor: pointer;

          &.router-link-active {
            background-image: linear-gradient(90deg, #D5EE71 0%, #7BE2C8 100%);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>